import {
  ref, onMounted, getCurrentInstance, reactive
} from 'vue'
import { jenisKendaraanUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { useRoute } from 'vue-router'

export default {
  name: 'TransportationTypeForm',
  props: {
    dataEdit: {
      default: () => null
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const store = useStore()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const dataEdit = ref(props.dataEdit)
    const Id = ref(null)
    const title = ref('Tambah Satuan Kategori')
    const submitted = ref(false)
    const route = useRoute()
    const dataForm = reactive({
      code: '',
      name: '',
      totalCapacity: 0
    })
    const rules = {
      code: {
        required: helpers.withMessage('Kode Jenis Kendaraan harus diisi', required)
      },
      name: {
        required: helpers.withMessage('Nama Jenis Kendaraan harus diisi', required)
      },
    }
    const v$ = useVuelidate(rules, dataForm)

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const getDataForm = async () => {
      title.value = 'Tambah Jenis Kendaraan'
      if (dataEdit.value) {
        Id.value = dataEdit.value.Id
        dataForm.code = dataEdit.value.Kode
        dataForm.name = dataEdit.value.Nama
        dataForm.totalCapacity = dataEdit.value.Kapasitas
        title.value = 'Edit Jenis Kendaraan'
      }
    }

    const proccessSubmit = async () => {
      store.dispatch('showLoading')
      const data = {
        Kode: dataForm.code,
        Nama: dataForm.name,
        Kapasitas: dataForm.totalCapacity
      }
      const response = await jenisKendaraanUseCase.submitData(Id.value, data)
      submitted.value = false

      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        const message = () => {
          if (response?.result?.Detail) {
            return response.result.detail ?? response.result.Detail
          } return $strInd.toast.successSubmit
        }
        $toast.add({
          severity: 'success', detail: message(), group: 'bc', life: 3000
        })
        emit('reloadData')
        closeSidebar()
      }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      proccessSubmit()
    }

    const deleteData = () => {
      $confirm.require({
        header: 'Jenis Kendaraan',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await jenisKendaraanUseCase.deleteData(Id.value)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: response.message, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 3000
            })
            emit('reloadData')
            closeSidebar()
          }
        }
      })
    }

    onMounted(() => {
      getDataForm()
    })
    return {
      title,
      dataEdit,
      dataForm,
      v$,
      route,
      submitted,
      submitData,
      deleteData,
      closeSidebar
    }
  }
}
