import {
  jenisKendaraanUseCase
} from '@/domain/usecase'
import {
  getCurrentInstance,
  onMounted,
  ref
} from 'vue'
import {
  useRoute
} from 'vue-router'
import {
  useStore
} from 'vuex'
import TransportationTypeForm from '@/views/master/courier/transportation-type/TransportationTypeForm.vue'

export default {
  name: 'TransportationType',
  components: {
    TransportationTypeForm
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd,
      $numbers
    } = app!.appContext.config.globalProperties
    const sliderActive = ref(false)
    const route = useRoute()
    const store = useStore()
    const data = ref([])
    const inputSearch = ref('')
    const op = ref()
    const dataForm = ref(null) as any
    const totalRecords = ref(0)
    const filters = {
      offset: 0,
      top: $numbers.totalItemPerPage,
      skip: 0,
      search: ''
    }

    const showDropDown = (evt: any, val: any) => {
      dataForm.value = val
      op.value.toggle(evt)
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const getAllData = () => {
      store.dispatch('showLoading')
      jenisKendaraanUseCase.getAll(filters).then((response: any) => {
        if (!response.error) {
          totalRecords.value = response.count
          data.value = response.result
        }
        store.dispatch('hideLoading')
      })
    }

    const onPage = (evt: any) => {
      filters.skip = evt.page * filters.top
      getAllData()
    }

    const deleteData = (val: any) => {
      $confirm.require({
        header: 'Jenis Kendaraan',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await jenisKendaraanUseCase.deleteData(val)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: response.message, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 3000
            })
            getAllData()
          }
        }
      })
    }

    const editData = (val: any) => {
      dataForm.value = val
      sliderActive.value = true
    }

    const searchData = (val: any) => {
      filters.search = val
      getAllData()
    }
    const redirectAdd = () => {
      dataForm.value = null
      sliderActive.value = true
    }

    onMounted(() => {
      getAllData()
    })
    return {
      filters,
      data,
      totalRecords,
      onPage,
      route,
      op,
      showDropDown,
      dataForm,
      deleteData,
      editData,
      sliderActive,
      closeSidebar,
      getAllData,
      redirectAdd,
      inputSearch,
      searchData
    }
  }
}
